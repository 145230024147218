import { useEffect, useState } from 'react'
import { base } from 'utils/constants'

const useAirtableGet = (url) => {
  const [data, setData] = useState([])

  useEffect(() => {
    base(url)
      .select({ view: 'Grid view' })
      .eachPage((records, fetchNextPage) => {
        if (url === 'Skills') {
          setData(records[0].fields)
        } else {
          setData(records)
        }
        fetchNextPage()
      })
  }, [url])

  return { data }
}

export default useAirtableGet
