import useAirtableGet from "hooks/useAirtableGet"
import LoadingExpCard from "utils/LoadingExpCard"
import SkillItem from "./SkillItem"

const Skills = () => {
  const { data } = useAirtableGet("Skills")

  const num = [1, 2, 3, 4, 5]

  if (data.length === 0) return num.map((n) => <LoadingExpCard key={n} />)
  return (
    <div>
      <SkillItem data={data.languages} title="Languages" />
      <SkillItem data={data.frontend} title="Frontend" />
      <SkillItem data={data.backend} title="Backend" />
      <SkillItem data={data.databases} title="Databases" />
      <SkillItem data={data.api} title="API Knowledge" />
      <SkillItem data={data.version} title="Version Control" />
      <SkillItem data={data.tools} title="Tools" />
    </div>
  )
}

export default Skills
