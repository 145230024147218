import Grid from '@mui/material/Grid'
import Box from '@mui/system/Box'
import useAirtableGet from 'hooks/useAirtableGet'
import LoadingCard from 'utils/LoadingCard'
import SingleCard from './SingleCard'

const Cards = () => {
  const { data } = useAirtableGet('Project')

  const num = [1, 2, 3]

  if (data.length === 0) return num.map((n) => <LoadingCard key={n} />)

  return (
    <Box py={5}>
      <Grid container spacing={5} alignItems="stretch">
        {data.map((item, i) => (
          <Grid key={i} item xs={12} sm={6} md={4}>
            <SingleCard item={item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Cards
