import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Link } from "react-scroll"
import TextTheme from "utils/TextTheme"

const Footer = () => {
  const isSmall = useMediaQuery("(max-width:600px)")
  return (
    <TextTheme>
      <footer>
        <Divider color="primary" textAlign="right">
          <Link smooth={true} duration={1000} offset={-1000} to="top">
            <Button
              variant="outlined"
              color="primary"
              sx={{ borderRadius: 0, p: 2 }}
            >
              <ArrowUpwardIcon />
            </Button>
          </Link>
        </Divider>

        <Typography variant="subtitle2" sx={{ m: 2, mt: 0, mb: 2.5 }}>
          Copyright © {new Date().getFullYear()}. {isSmall && <br />} All rights
          reserved by Saif Rahman.
        </Typography>
      </footer>
    </TextTheme>
  )
}

export default Footer
