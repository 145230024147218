import { Route, Routes } from "react-router-dom"
import Home from "screens/Home"
import Portfolio from "screens/Portfolio"

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/portfolio" element={<Portfolio />} />
    </Routes>
  )
}

export default AppRoutes
