import { useState } from "react"

// Swiper Imports
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/effect-cube"
import "swiper/css/pagination"

// import required modules

import { Autoplay, EffectCube, Pagination } from "swiper"
import CarouselItems from "./CarouselItem"
import Modal from "./modal"

const Carousel = ({ projects }) => {
  const [open, setOpen] = useState(false)
  const [current, setCurrent] = useState(projects[0])

  console.log({ projects })

  if (projects.length === 0) return "nai"

  return (
    <Swiper
      loop
      speed={1200}
      autoplay={{ delay: 2000, disableOnInteraction: false }}
      effect={"cube"}
      grabCursor={true}
      cubeEffect={{
        shadow: true,
        slideShadows: false,
        shadowOffset: 20,
        shadowScale: 0.94,
      }}
      modules={[EffectCube, Pagination, Autoplay]}
    >
      {projects.map((project) => (
        <SwiperSlide
          style={{ cursor: "pointer" }}
          onClick={() => {
            setOpen(true)
            setCurrent(project)
          }}
          key={project.id}
        >
          <CarouselItems
            title={project.fields.title}
            src={project.fields.image[0].url}
            techs={project.fields.tech}
          />
        </SwiperSlide>
      ))}
      {current && <Modal open={open} setOpen={setOpen} item={current} />}
    </Swiper>
  )
}

export default Carousel
