import CloseIcon from '@mui/icons-material/Close'
import FilterTiltShiftIcon from '@mui/icons-material/FilterTiltShift'
import LinkIcon from '@mui/icons-material/Link'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Slide from '@mui/material/Slide'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import * as React from 'react'
import Footer from './Footer'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Modal({ open, setOpen, item }) {
  const handleClose = () => {
    setOpen(false)
  }
  const isSmall = useMediaQuery('(max-width:600px)')

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            position: 'relative',
            color: 'primary',
            backgroundColor: 'background.paper',
          }}
        >
          <Toolbar>
            <Typography
              sx={{ ml: 2, flex: 1, color: 'primary.main' }}
              variant="h6"
              component="div"
            >
              {item.fields.title}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              sx={{ color: 'primary.main' }}
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Container maxWidth="xl" sx={{ my: 2 }}>
          <Box px={isSmall ? 0 : 8}>
            <Grid container spacing={5} alignItems="stretch">
              <Grid item xs={12} sm={7}>
                <img src={item.fields.image[0].url} alt="hmm" width="100%" />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Typography color="primary" variant="h6">
                  DESCRIPTION
                </Typography>
                <List>
                  <ListItem button>
                    <ListItemText
                      primary="Title"
                      secondary={item.fields.title}
                    />
                  </ListItem>
                  <ListItem button>
                    <ListItemText
                      primary="Client"
                      secondary={item.fields.client}
                    />
                  </ListItem>

                  <ListItem button>
                    <ListItemText
                      primary="Year"
                      secondary={item.fields.year.split('-')[0]}
                    />
                  </ListItem>
                  <ListItem button>
                    <ListItemText primary="Tech used" />
                  </ListItem>
                  {item.fields.tech.map((tech, i) => (
                    <Button
                      size="small"
                      key={i}
                      sx={{ ml: 2, marginTop: '-10px', mb: 2, borderRadius: 0 }}
                      variant="outlined"
                    >
                      {tech}
                    </Button>
                  ))}

                  <ListItem button>
                    <ListItemText primary="Live preview" />
                  </ListItem>
                  <Button
                    component="a"
                    href={item.fields.link}
                    target="blank"
                    size="small"
                    sx={{ ml: 2, borderRadius: 0, marginTop: '-10px' }}
                    variant="outlined"
                    endIcon={<LinkIcon />}
                  >
                    {item.fields.device[0] === 'web' ? 'Visit' : 'Download'}
                  </Button>
                </List>
              </Grid>
            </Grid>

            <Typography sx={{ my: 4 }} color="text.secondary" variant="h6">
              {item.fields.description}
            </Typography>

            <Box my={4} />
            <Typography color="primary" variant="h6">
              FEATURES
            </Typography>
            <List>
              {item.fields.features.map((tech, i) => (
                <ListItem disablePadding key={i}>
                  <ListItemButton>
                    <ListItemIcon>
                      <FilterTiltShiftIcon color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary={tech} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Container>
        <Footer setOpen={setOpen} />
      </Dialog>
    </div>
  )
}
