import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import CardTabs from 'components/Cards/'
import PortNav from 'components/PortNav'
import useMediaQuery from '@mui/material/useMediaQuery'
import Footer from 'components/Footer'
import LoadingShort from 'LoadingShort'

const Portfolio = () => {
  const isSmall = useMediaQuery('(max-width:600px)')
  return (
    <Box>
      <LoadingShort />
      <PortNav />
      <Container maxWidth='xl' id='top'>
        <Box my={2}>
          <Typography align='center' color='primary' variant='h6'>
            PORTFOLIO
          </Typography>
          <Box py={1} />
          <Typography
            align='center'
            color='text.secondary'
            variant={isSmall ? 'h4' : 'h3'}
          >
            All My Projects
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Divider
              sx={{
                mt: 2,
                height: '2px',
                width: '150px',

                background: '#FFFFFF33',
              }}
            />
          </Box>
          <Box py={3} />
          <CardTabs />
        </Box>
      </Container>
      <Footer />
    </Box>
  )
}

export default Portfolio
