import CottageIcon from '@mui/icons-material/Cottage'
import HelpCenterIcon from '@mui/icons-material/HelpCenter'
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium'
import WorkIcon from '@mui/icons-material/Work'
import ContactMailIcon from '@mui/icons-material/ContactMail'

const data = [
  {
    id: 1,
    name: 'Home',
    to: 'top',
    offset: -1000,

    icon: <CottageIcon color='secondary' />,
  },
  {
    id: 2,
    name: 'About',
    to: 'about',
    offset: 0,
    icon: <HelpCenterIcon color='secondary' />,
  },
  {
    id: 3,
    name: 'Experience',
    offset: -100,
    to: 'exp',
    icon: <WorkspacePremiumIcon color='secondary' />,
  },
  {
    id: 4,
    name: 'Portfolio',
    offset: -100,
    to: 'portfolio',
    icon: <WorkIcon color='secondary' />,
  },
  {
    id: 5,
    name: 'Contact',
    offset: 0,
    to: 'contact',
    icon: <ContactMailIcon color='secondary' />,
  },
]

export default data
