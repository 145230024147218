import Grid from '@mui/material/Grid'
import Box from '@mui/system/Box'
import useAirtableGetFiltered from 'hooks/useAirtableGetFiltered'
import LoadingCard from 'utils/LoadingCard'
import SingleCard from './SingleCard'

const FilteredCards = ({ filter }) => {
  const { data } = useAirtableGetFiltered(filter)

  const num = [1, 2, 3, 4, 5, 6]

  return (
    <Box py={5}>
      <Grid container spacing={5}>
        {data.length === 0
          ? num.map((item, i) => (
              <Grid key={i} item xs={12} sm={6} md={4}>
                <LoadingCard />
              </Grid>
            ))
          : data.map((item, i) => (
              <Grid key={i} item xs={12} sm={6} md={4}>
                <SingleCard item={item} />
              </Grid>
            ))}
      </Grid>
    </Box>
  )
}

export default FilteredCards
