import Box from '@mui/system/Box'
import Navbar from 'components/Navbar'
import Grid from '@mui/material/Grid'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import useMediaQuery from '@mui/material/useMediaQuery'
import RightSide from 'components/RightSide'
import Introduction from 'components/Introduction'
import AboutMe from 'components/AboutMe'
import ExpTabs from 'components/Experience'
import Portfolio from 'components/Portfolio'
import Contact from 'components/Contact'
import Footer from 'components/Footer'

const Home = ({ window }) => {
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })
  const isSmall = useMediaQuery('(max-width:600px)')
  return (
    <Box>
      <Navbar />
      <Grid container spacing={0} sx={{ flexWrap: 'wrap-reverse' }}>
        <Grid
          container
          sx={{ transition: 'all 0.5s ease-in-out' }}
          item
          xs={12}
          sm={trigger ? 8 : 7}
          //   spacing={3}
        >
          <Grid id='top' item xs={12}>
            <Introduction />
          </Grid>
          <Grid id='about' item xs={12}>
            <AboutMe />
          </Grid>
          <Grid id='exp' item xs={12}>
            <ExpTabs />
          </Grid>
          <Grid id='portfolio' item xs={12}>
            <Portfolio />
          </Grid>

          <Grid id='contact' item xs={12}>
            <Contact />
          </Grid>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
        <Grid
          sx={{
            transition: 'all 0.5s ease-in-out',
            position: isSmall ? 'static' : 'fixed',
            top: 0,
            right: 0,
            zIndex: 5,
            width: '100%',
          }}
          item
          xs={12}
          sm={trigger ? 4 : 5}
        >
          <RightSide />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Home
