import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"

import Typography from "@mui/material/Typography"

const Info = () => {
  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={4}>
        <Button sx={{ textTransform: "none" }}>
          <Typography
            color="text.primary"
            sx={{
              typography: {
                xs: "subtitle1",
                sm: "h6",
              },
            }}
          >
            Name:
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={8}>
        <Button sx={{ textTransform: "none" }}>
          <Typography
            color="text.secondary"
            sx={{
              typography: {
                xs: "subtitle1",
                sm: "h6",
              },
            }}
          >
            Saif Rahman
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button sx={{ textTransform: "none" }}>
          <Typography
            color="text.primary"
            sx={{
              typography: {
                xs: "subtitle1",
                sm: "h6",
              },
            }}
          >
            Birthday:
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={8}>
        <Button sx={{ textTransform: "none" }}>
          <Typography
            color="text.secondary"
            sx={{
              typography: {
                xs: "subtitle1",
                sm: "h6",
              },
            }}
          >
            January 12th
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button sx={{ textTransform: "none" }}>
          <Typography
            color="text.primary"
            sx={{
              typography: {
                xs: "subtitle1",
                sm: "h6",
              },
            }}
          >
            Address:
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={8}>
        <Button sx={{ textTransform: "none" }}>
          <Typography
            color="text.secondary"
            sx={{
              typography: {
                xs: "subtitle1",
                sm: "h6",
              },
            }}
          >
            Cantonment, Dhaka.
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button sx={{ textTransform: "none" }}>
          <Typography
            color="text.primary"
            sx={{
              typography: {
                xs: "subtitle1",
                sm: "h6",
              },
            }}
          >
            Phone:
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={8}>
        <Button
          component="a"
          href="tel:+8801738829050"
          sx={{ textTransform: "none" }}
        >
          <Typography
            color="text.secondary"
            sx={{
              typography: {
                xs: "subtitle1",
                sm: "h6",
              },
            }}
          >
            01738829050
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button sx={{ textTransform: "none" }}>
          <Typography
            color="text.primary"
            sx={{
              typography: {
                xs: "subtitle1",
                sm: "h6",
              },
            }}
          >
            Email:
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={8}>
        <Button
          component="a"
          href="mailto:contact@engineersaif.com"
          sx={{ textTransform: "none" }}
        >
          <Typography
            color="text.secondary"
            sx={{
              typography: {
                xs: "subtitle1",
                sm: "h6",
              },
            }}
          >
            contact@engineersaif.com
          </Typography>
        </Button>
      </Grid>
    </Grid>
  )
}

export default Info
