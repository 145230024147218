import DownloadingIcon from "@mui/icons-material/Downloading"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import useMediaQuery from "@mui/material/useMediaQuery"
import pdf from "assets/pdf/resume.pdf"
import Info from "./Info"

const AboutMe = () => {
  const isSmall = useMediaQuery("(max-width:600px)")

  // Current date
  const currentDate = new Date()

  // Given date - replace 'YYYY-MM-DD' with the actual date
  const givenDate = new Date("2019-01-12")

  // Difference in milliseconds
  const differenceInMilliseconds = Math.abs(currentDate - givenDate)

  // Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 milliseconds)
  const differenceInDays = differenceInMilliseconds / (24 * 60 * 60 * 1000)

  const differenceInYears = differenceInDays / 365
  return (
    <Container sx={{ my: 13 }}>
      <Box px={isSmall ? 0 : 7}>
        <Typography color="primary" variant="h6">
          ABOUT ME
        </Typography>
        <Box py={3} />
        <Typography color="text.secondary" variant={isSmall ? "h4" : "h3"}>
          Biography
        </Typography>
        <Divider
          sx={{
            mt: 2,
            height: "2px",
            width: "150px",

            background: "#FFFFFF33",
          }}
        />

        <Typography
          sx={{ mt: 5 }}
          color="text.primary"
          variant={isSmall ? "h6" : "h5"}
        >
          As a Software Engineer with more than {Math.floor(differenceInYears)}{" "}
          years of experience in React.js, Angular.js, Next.js & Flutter. I view
          my work as a blend of art & technology. I see coding not just as
          writing lines of text, but as creating a beautiful & complex design.
          For me, every project is an opportunity to turn a simple digital space
          into something visually stunning & highly functional. Each piece of
          work is carefully crafted, like a sculpture, combining elegance with
          practicality.
        </Typography>
        <Box py={3} />
        <Info />
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          component="a"
          href={pdf}
          download
          sx={{ borderRadius: 0, mt: 5, py: 1 }}
          endIcon={<DownloadingIcon />}
        >
          Download CV
        </Button>
      </Box>
    </Container>
  )
}

export default AboutMe
