import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material"

const CarouselItems = ({ title, src, techs }) => {
  return (
    <Card sx={{ height: "100%", flexDirection: "column", display: "flex" }}>
      <CardActionArea
        component="div"
        sx={{
          display: "flex",
          flex: "1 1 auto",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <CardMedia component="img" image={src} width="100%" alt={title} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h5">
            {title}
          </Typography>
          {techs.map((tech) => (
            <Button
              size="small"
              key={tech}
              sx={{ mr: 1, mt: 2, borderRadius: 0 }}
              variant="outlined"
            >
              {tech}
            </Button>
          ))}
        </CardContent>
      </CardActionArea>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          sx={{ borderRadius: 0 }}
        >
          View
        </Button>
      </CardActions>
    </Card>
  )
}

export default CarouselItems
