import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import useAirtableGet from "hooks/useAirtableGet"
import LoadingExpCard from "utils/LoadingExpCard"
import "./style.css"

export default function Experience() {
  const { data } = useAirtableGet("Experience")

  const num = [1, 2, 3]

  if (data.length === 0) return num.map((n) => <LoadingExpCard key={n} />)
  return (
    <Box>
      {data.map((exp) => (
        <Card key={exp.id} sx={{ mb: 3, p: 2 }} className="item">
          <CardContent>
            <Typography
              color="text.secondary"
              sx={{ typography: { xs: "h6", sm: "h5" } }}
              gutterBottom
            >
              {exp.fields.company}
              {"   "}
              <Typography
                component="span"
                color="text.primary"
                display="inline"
                variant="subtitle2"
              >
                ({exp.fields.startDate.split("-")[0]} -{" "}
                {exp.fields.working === "working"
                  ? "Present"
                  : exp.fields.endDate.split("-")[0]}
                )
              </Typography>
            </Typography>
            <Box py={1} />
            <Typography
              sx={{ typography: { xs: "h5", sm: "h4" } }}
              color="text.secondary"
            >
              {exp.fields.title}
            </Typography>
            <Box py={1} />
            <Typography
              sx={{
                fontWeight: 400,
                listStyleType: "square",
                typography: { xs: "subtitle1", sm: "h6" },
              }}
              color="text.primary"
            >
              {exp.fields.description.map((desc, i) => (
                <li key={i}>{desc}</li>
              ))}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  )
}
