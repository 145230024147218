import CloseIcon from "@mui/icons-material/Close"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import useMediaQuery from "@mui/material/useMediaQuery"
import TextTheme from "utils/TextTheme"

const Footer = ({ setOpen }) => {
  const isSmall = useMediaQuery("(max-width:600px)")
  return (
    <TextTheme>
      <footer>
        <Divider color="primary" textAlign="right">
          <Button
            onClick={() => setOpen(false)}
            variant="outlined"
            color="primary"
            sx={{ borderRadius: 0, p: 2 }}
          >
            <CloseIcon />
          </Button>
        </Divider>

        <Typography variant="subtitle2" sx={{ m: 2, mt: 0, mb: 2.5 }}>
          Copyright © {new Date().getFullYear()}. {isSmall && <br />} All rights
          reserved by Saif Rahman.
        </Typography>
      </footer>
    </TextTheme>
  )
}

export default Footer
