import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery'

const LoadingCarousel = () => {
  const isSmall = useMediaQuery('(max-width:600px)')
  return (
    <Container sx={{ mt: 3 }}>
      <Box px={isSmall ? 0 : 7}>
        <Skeleton width='100%' style={{ paddingBottom: '56%' }}>
          <img src='hmm' alt='hmm' width='100%' />
        </Skeleton>

        <Skeleton width='100%'>
          <Button
            size='small'
            fullWidth
            sx={{ mr: 2, my: 2, borderRadius: 0 }}
            variant='outlined'
          >
            Hmmm
          </Button>
        </Skeleton>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Skeleton width='100%'>
              <Typography color='text.primary' variant={isSmall ? 'h5' : 'h4'}>
                Yeah Lol
              </Typography>
            </Skeleton>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Skeleton width='100%'>
              <Button
                variant='outlined'
                color='primary'
                fullWidth
                sx={{ borderRadius: 0, py: 1 }}
              >
                View
              </Button>
            </Skeleton>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default LoadingCarousel
