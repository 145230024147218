import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import Typography from "@mui/material/Typography"
import * as React from "react"

import CloseIcon from "@mui/icons-material/Close"
import FacebookIcon from "@mui/icons-material/Facebook"
import GitHubIcon from "@mui/icons-material/GitHub"
import InstagramIcon from "@mui/icons-material/Instagram"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import MenuIcon from "@mui/icons-material/Menu"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"

import { Link } from "react-scroll"
import data from "./data"

export default function Menu() {
  const [state, setState] = React.useState({
    right: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const list = (anchor) => (
    <Box
      p={2}
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 350 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem
          secondaryAction={
            <IconButton edge="end" aria-label="delete">
              <CloseIcon color="primary" fontSize="large" />
            </IconButton>
          }
        >
          <Typography variant="h5">Menu</Typography>
        </ListItem>
        <Box my={3} />
        {data.map((item) => (
          <Link
            smooth={true}
            duration={1000}
            offset={item.offset}
            to={item.to}
            key={item.id}
          >
            <ListItem onClick={toggleDrawer(anchor, false)} button>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          </Link>
        ))}
      </List>
      <Box
        sx={{
          height: "80%",
          display: "flex",
          alignItems: "end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ListItem onClick={toggleDrawer(anchor, false)} button>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              component="a"
              href="https://www.facebook.com/Saif.Newaz/"
              target="blank"
              sx={{ borderRadius: 0, mr: 0.5 }}
            >
              <FacebookIcon />
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              component="a"
              href="https://www.instagram.com/saif.newaz/"
              target="blank"
              sx={{ borderRadius: 0, mr: 0.5 }}
            >
              <InstagramIcon />
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              component="a"
              href="https://www.linkedin.com/in/srman/"
              target="blank"
              sx={{ borderRadius: 0, mr: 0.5 }}
            >
              <LinkedInIcon />
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              component="a"
              href="https://github.com/MrNewaz"
              target="blank"
              sx={{ borderRadius: 0 }}
            >
              <GitHubIcon />
            </Button>
          </ListItem>
          <ListItem sx={{ mt: 1 }} onClick={toggleDrawer(anchor, false)}>
            <Typography>Developed by Saif Rahman</Typography>
          </ListItem>
        </Box>
      </Box>
    </Box>
  )

  return (
    <Box>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            variant="outlined"
            color="primary"
            sx={{ backgroundColor: "#1D1B19", borderRadius: 0 }}
            onClick={toggleDrawer(anchor, true)}
            endIcon={<MenuIcon />}
          >
            Menu
          </Button>

          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </Box>
  )
}
