import { Box } from "@mui/system"
import { useEffect, useState } from "react"
import HashLoader from "react-spinners/HashLoader"

const Loading = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 4000)
  }, [])
  return (
    <Box
      className="height-100"
      sx={{
        zIndex: 5000,
        position: "fixed",
        transition: "all 0.6s ease-in-out",
        transform: loading ? "translate(-0%,-0%)" : "translate(-100%,-0%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        backgroundColor: "#1d1b19",
      }}
    >
      <Box>
        <HashLoader color={"#987750"} loading={loading} size={70} />
      </Box>
    </Box>
  )
}

export default Loading
