import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import useAirtableGet from 'hooks/useAirtableGet'
import LoadingExpCard from 'utils/LoadingExpCard'
import './style.css'

export default function Education() {
  const { data } = useAirtableGet('Education')

  const num = [1, 2, 3]

  if (data.length === 0) return num.map((n) => <LoadingExpCard key={n} />)

  return (
    <Box>
      {data.map((edu) => (
        <Card key={edu.id} sx={{ mb: 3, p: 2 }} className="item">
          <CardContent>
            <Typography
              color="text.primary"
              sx={{ typography: { xs: 'h6', sm: 'h5' } }}
              gutterBottom
            >
              {edu.fields.subject}
            </Typography>
            <Box py={1} />
            <Typography
              sx={{ typography: { xs: 'h5', sm: 'h4' } }}
              color="text.secondary"
            >
              {edu.fields.institute}
            </Typography>
            <Box py={1} />
            <Typography
              sx={{
                fontWeight: 400,
                typography: { xs: 'subtitle1', sm: 'h6' },
              }}
              color="text.primary"
            >
              {edu.fields.description}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  )
}
