import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import useAirtableGet from "hooks/useAirtableGet"
import PropTypes from "prop-types"
import * as React from "react"
import LoadingTab from "utils/LoadingTab"
import Cards from "./Cards"
import FilteredCards from "./FilteredCards"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  }
}

export default function CardTabs() {
  const isSmall = useMediaQuery("(max-width:600px)")
  const theme = useTheme()

  const { data } = useAirtableGet("Category")
  const [value, setValue] = React.useState(0)

  if (data.length === 0) return <LoadingTab />

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ bgcolor: "background.paper", my: 2 }}>
      <AppBar position="static" sx={{ bgcolor: "background.paper" }}>
        <Tabs
          component="div"
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant={isSmall ? "scrollable" : "fullWidth"}
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab label="All" {...a11yProps(0)} />
          {data.map((cat) => (
            <Tab key={cat.id} label={cat.fields.name} {...a11yProps(cat.id)} />
          ))}
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0} dir={theme.direction}>
        <Cards />
      </TabPanel>
      {data.map((cat, i) => (
        <TabPanel
          key={cat.id}
          value={value}
          index={i + 1}
          dir={theme.direction}
        >
          <FilteredCards filter={cat.fields.name} />
        </TabPanel>
      ))}
    </Box>
  )
}
