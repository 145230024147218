import Grid from '@mui/material/Grid'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import TextTheme from 'utils/TextTheme'
import { useForm } from '@formspree/react'

const Form = () => {
  const isSmall = useMediaQuery('(max-width:600px)')
  const [state, handleSubmit] = useForm('mjvpnbpj')
  if (state.succeeded) {
    return (
      <Typography
        sx={{ mt: 2 }}
        color='primary'
        variant={isSmall ? 'h6' : 'h5'}
      >
        Thank you for your query. I will get back to you soon. Have a nice day!
      </Typography>
    )
  }
  return (
    <TextTheme>
      <Box component='form' autoComplete='off' onSubmit={handleSubmit}>
        <Grid container spacing={3} alignItems='stretch'>
          <Grid item xs={12} sm={6}>
            <TextField
              InputProps={{
                sx: { borderRadius: 0 },
              }}
              id='name'
              name='name'
              label='Name'
              fullWidth
              variant='outlined'
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              InputProps={{
                sx: { borderRadius: 0 },
              }}
              id='email'
              type='email'
              name='email'
              label='Email'
              fullWidth
              variant='outlined'
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              InputProps={{
                sx: { borderRadius: 0 },
              }}
              id='phone'
              name='phone'
              label='Phone'
              fullWidth
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputProps={{
                sx: { borderRadius: 0 },
              }}
              id='message'
              name='message'
              multiline
              rows={4}
              label='Message'
              fullWidth
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={state.submitting}
              // loadingPosition='start'
              loadingIndicator='Submitting...'
              variant='outlined'
              color='primary'
              type='submit'
              fullWidth
              sx={{ borderRadius: 0, py: 1 }}
            >
              Send Message
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </TextTheme>
  )
}

export default Form
