import AppBar from '@mui/material/AppBar'
import { Link } from 'react-router-dom'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import HomeIcon from '@mui/icons-material/Home'

export default function PortNav() {
  return (
    <AppBar
      elevation={0}
      sx={{ backgroundColor: 'transparent', my: 1 }}
      position='static'
    >
      <Toolbar>
        {/* <Box sx={{ flexGrow: 1 }} /> */}
        <Button
          component={Link}
          to='/'
          variant='outlined'
          color='primary'
          sx={{ backgroundColor: '#1D1B19', borderRadius: 0 }}
          startIcon={<HomeIcon />}
        >
          Home
        </Button>
      </Toolbar>
    </AppBar>
  )
}
