import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import useMediaQuery from "@mui/material/useMediaQuery"
import Form from "./Form"

const Contact = () => {
  const isSmall = useMediaQuery("(max-width:600px)")
  return (
    <Container sx={{ my: 13 }}>
      <Box px={isSmall ? 0 : 7}>
        <Typography color="primary" variant="h6">
          CONTACT
        </Typography>
        <Box py={3} />
        <Typography color="text.secondary" variant={isSmall ? "h4" : "h3"}>
          Get In Touch
        </Typography>
        <Divider
          sx={{
            mt: 2,
            height: "2px",
            width: "150px",

            background: "#FFFFFF33",
          }}
        />

        <Typography
          sx={{ mt: 5 }}
          color="text.primary"
          variant={isSmall ? "h5" : "h4"}
        >
          If you have any suggestion, project or just you want to say “hello”,
          please fill out the form below and I will reply shortly.
        </Typography>
        <Box py={3} />
        <Form />
      </Box>
    </Container>
  )
}

export default Contact
