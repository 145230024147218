import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import useAirtableGetFeatured from 'hooks/useAirtableGetFeatured'
import { Link } from 'react-router-dom'
import LoadingCarousel from 'utils/LoadingCarousel'
import Carousel from './carousel'

const Portfolio = () => {
  const isSmall = useMediaQuery('(max-width:600px)')

  const { data } = useAirtableGetFeatured('Project')

  if (data.length === 0) return <LoadingCarousel />

  return (
    <Container sx={{ mt: 3 }}>
      <Box px={isSmall ? 0 : 7}>
        <Typography color="primary" variant="h6">
          PORTFOLIO
        </Typography>
        <Box py={3} />
        <Typography color="text.secondary" variant={isSmall ? 'h4' : 'h3'}>
          Featured Projects
        </Typography>
        <Divider
          sx={{
            mt: 2,
            height: '2px',
            width: '150px',

            background: '#FFFFFF33',
          }}
        />
        <Box py={3} />

        {data.length !== 0 && <Carousel projects={data} />}
        <Button
          component={Link}
          to="portfolio"
          variant="outlined"
          color="primary"
          fullWidth
          sx={{ borderRadius: 0, mt: 5, py: 1 }}
        >
          View All Projects
        </Button>
      </Box>
    </Container>
  )
}

export default Portfolio
