import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import LoadingExpCard from 'utils/LoadingExpCard'

const SkillItem = ({ title, data }) => {
  if (data.length === 0) return <LoadingExpCard />
  return (
    <Box mb={3}>
      <Typography color="text.secondary" variant="h5">
        {title}:
      </Typography>
      <Divider
        sx={{
          my: 1,
          height: '2px',
          width: '100px',

          background: '#FFFFFF33',
        }}
      />
      {data.map((item) => (
        <Button
          key={item}
          sx={{ mr: 2, my: 1.5, borderRadius: 0 }}
          variant="outlined"
        >
          {item}
        </Button>
      ))}
    </Box>
  )
}

export default SkillItem
