import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import AppRoutes from 'Routes'
import Loading from 'Loading'
import ScrollToTop from 'utils/ScrollToTop'

const App = ({ darkmode = false }) => {
  const theme = createTheme({
    palette: {
      mode: darkmode ? 'dark' : 'light',
      primary: {
        main: '#987750',
        contrastText: '#888',
      },
      secondary: {
        main: '#888',
      },
      text: {
        primary: '#888',
        secondary: '#DDDDDD',
      },
      background: {
        default: '#1d1b19',
        paper: '#181715',
      },
    },
    typography: {
      fontFamily: 'Jost',

      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 600,
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Loading />
      <ScrollToTop />
      <AppRoutes />
    </ThemeProvider>
  )
}

export default App
