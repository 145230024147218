import img from "assets/right.jpg"
import { Link } from "react-scroll"
import Typewriter from "typewriter-effect"
import "./style.css"

const RightSide = () => {
  return (
    <div className="resumo_fn_right">
      <div className="right_in">
        <div className="right_top">
          <div className="border1"></div>
          <div className="border2"></div>

          <div className="img_holder">
            <img src={img} alt="saif" width="100%" />
          </div>
          <div className="title_holder">
            <h5>Hi There! I am</h5>
            <h3>
              <span className="animated_title">
                <Typewriter
                  options={{
                    strings: ["Saif Rahman", "An Engineer", "A Solutionist"],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </span>
            </h3>
          </div>
        </div>
        <div className="right_bottom">
          <Link
            style={{ cursor: "pointer" }}
            smooth={true}
            duration={1000}
            to="contact"
          >
            <span className="circle"></span>
            <span className="text">I’m available for work. Hire me.</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default RightSide
