import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import useMediaQuery from "@mui/material/useMediaQuery"
import sign from "assets/svg/signature.svg"

const Introduction = () => {
  const isSmall = useMediaQuery("(max-width:600px)")
  return (
    <Container sx={{ mt: 10 }}>
      <Box px={isSmall ? 0 : 7}>
        <Typography color="primary" variant="h6">
          INTRODUCTION
        </Typography>
        <Box py={3} />
        <Typography color="text.secondary" variant={isSmall ? "h4" : "h3"}>
          Senior Software Engineer
        </Typography>
        <Divider
          sx={{
            mt: 2,
            height: "2px",
            width: "150px",

            background: "#FFFFFF33",
          }}
        />

        <Typography
          sx={{ mt: 5 }}
          color="text.primary"
          variant={isSmall ? "h6" : "h5"}
        >
          Enhance your digital journey with my unique blend of design &
          development skills. As a software engineer, I specialize in crafting
          solutions that are not just functional, but also simple, aesthetically
          pleasing & deeply meaningful. Let's create something beautiful
          together!
        </Typography>
        <Box py={3} />

        <img src={sign} alt="sign" />
      </Box>
    </Container>
  )
}

export default Introduction
