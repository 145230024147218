import { useEffect, useState } from 'react'
import { base } from 'utils/constants'

const useAirtableGetFiltered = (id) => {
  const [data, setData] = useState([])

  useEffect(() => {
    base('Project')
      .select({
        view: 'Grid view',
        filterByFormula: `Find("${id}", {categoryName}& '')`,
      })
      .eachPage((records, fetchNextPage) => {
        setData(records)
        fetchNextPage()
      })
  }, [id])

  return { data }
}

export default useAirtableGetFiltered
