import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
// import Button from '@mui/material/Button'
// import MenuIcon from '@mui/icons-material/Menu'
import Menu from './Menu'

export default function Navbar() {
  return (
    <AppBar
      elevation={0}
      sx={{ backgroundColor: 'transparent', top: '1rem' }}
      position='sticky'
    >
      <Toolbar>
        <Box sx={{ flexGrow: 1 }} />
        {/* <Button
          variant='outlined'
          color='primary'
          sx={{ backgroundColor: '#1D1B19', borderRadius: 0 }}
          endIcon={<MenuIcon />}
        >
          Menu
        </Button> */}
        <Menu />
      </Toolbar>
    </AppBar>
  )
}
