import { LinearProgress } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

const LoadingTab = () => {
  return (
    <Box sx={{ bgcolor: 'background.paper', mt: 2, mb: 5 }}>
      <AppBar position="static" sx={{ bgcolor: 'background.paper' }}>
        <Tabs
          value={0}
          component="div"
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab label="All" />
        </Tabs>
        <LinearProgress color="primary" />
      </AppBar>
    </Box>
  )
}

export default LoadingTab
