import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import Box from '@mui/system/Box'
import { useState } from 'react'
import Modal from './modal'

const styles = {
  card: {
    backgroundColor: 'background.default',
    borderRadius: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    transition: '0.2s',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: 2,
    },
  },
  cardMedia: {
    paddingTop: '56.25%',
    width: '100%',
    objectFit: 'cover',
  },
}

export default function SingleCard({ item }) {
  const [open, setOpen] = useState(false)
  // console.log(item)
  return (
    <Card sx={styles.card} align="left">
      <CardActionArea component="div" onClick={() => setOpen(true)}>
        <CardMedia
          sx={styles.cardMedia}
          image={item.fields.image[0].url}
          title="Card img"
        />
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Typography gutterBottom variant="h5" component="h2">
              {item.fields.title}
            </Typography>
          </Box>
          {item.fields.tech.map((tech) => (
            <Button
              size="small"
              key={tech}
              sx={{ mr: 1, mb: 1, borderRadius: 0 }}
              variant="outlined"
            >
              {tech}
            </Button>
          ))}
        </CardContent>
      </CardActionArea>

      <CardActions>
        <Modal open={open} setOpen={setOpen} item={item} />
        <Button
          onClick={() => setOpen(true)}
          variant="outlined"
          color="primary"
          fullWidth
          sx={{ borderRadius: 0, py: 1 }}
        >
          View
        </Button>
      </CardActions>
    </Card>
  )
}
