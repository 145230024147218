import { useEffect, useState } from 'react'
import { base } from 'utils/constants'

const useAirtableGetFeatured = () => {
  const [data, setData] = useState([])

  useEffect(() => {
    base('Project')
      .select({
        view: 'Grid view',
        filterByFormula: 'AND({isFeatured})',
      })
      .eachPage((records, fetchNextPage) => {
        setData(records)
        fetchNextPage()
      })
  }, [])

  return { data }
}

export default useAirtableGetFeatured
