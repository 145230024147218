import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'

const TextTheme = ({ children }) => {
  const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#987750',
        contrastText: '#888',
      },
      secondary: {
        main: '#888',
      },
      text: {
        primary: '#888',
        secondary: '#DDDDDD',
      },
      background: {
        default: '#1d1b19',
        paper: '#181715',
      },
    },
    typography: {
      fontFamily: 'Jost',

      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 600,
    },
  })
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default TextTheme
